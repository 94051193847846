.UserForm-table {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  padding: .5rem;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.UserForm-left {
  margin: auto;
  min-height: 50vh;
}

.UserForm-right {
  margin: auto;
  max-width: 100%;
  min-width: 60%;
}

.UserForm-preview {
  height: 50vh;
  object-fit: contain;
}

.UserForm-label {
  font-size: 1rem;
  text-align: center;
}

.UserForm-input {
  font-size: 1rem;
  padding: .2rem;
  text-align: left;
  width: 80%;
}

.UserForm-button {
  margin: 1rem;
}

.UserForm-button Button {
  margin: .5rem;
}