.InventoryCard {
  flex-flow: row;
  margin: .25rem;
}

.InventoryCard-itemImg {
  height: 75px;
  width: 75px;
  object-fit: contain;
}
