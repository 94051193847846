.Items-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.Items-title {
  color: white;
  background-color: slategray;
  font-size: calc(8px + 2vmin);
}

.Items-item {
  width: 20vw;
}

.Items-description {
  width: 30vw;
}

.Items-price {
  width: 8vw;
  text-align: right;
}

.Items-stock {
  width: 8vw;
  text-align: center;
}

.Items-date {
  width: 15vw;
  text-align: right;
}

.ItemsRow {
  font-size: calc(7px + 1.75vmin);
  border-bottom: 1px solid slategray;
}

.ItemsRow td {
  padding: .25rem;
}

.ItemsRow-item {
  display: flex;
}

.ItemsRow-itemImg {
  height: 50px;
  max-width: 50px;
  object-fit: contain;
}

.ItemsRow-name {
  padding-left: .5rem;
  margin: auto;
  margin-left: 0;
  text-align: left;
  color: black;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ItemsRow-item:hover {
  background-color: rgb(214, 220, 225);
}

.ItemsRow-description {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ItemsRow-price, .ItemsRow-date {
  text-align: right;
}

.ItemsRow-stock {
  text-align: center;
}