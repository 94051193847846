.TransactionDetails {
  border-collapse: collapse;
  margin: auto;
  text-align: left;
  max-width: 65vw;
  min-width: 20vw;
}

.TransactionDetails-body {
  column-count: 2;
  margin-bottom: 1rem;
  border-bottom: 1px solid slategray;
}

.TransactionDetails .even {
  background-color: rgb(214, 220, 225);
  font-size: calc(8px + 2vmin);
}

.TransactionDetails .odd {
  font-size: calc(8px + 2vmin);
}

.TransactionDetails-button {
  text-align: center;
  margin: .5rem;
}

.TransactionDetails Button {
  margin: .5rem;
}

.Button-text {
  margin-left: .25rem;
}

#action {
  text-transform: capitalize;
}