.ItemForm-table {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  padding: .5rem;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.ItemForm-left {
  margin: auto;
  min-height: 50vh;
  min-width: 40%;
}

.ItemForm-right {
  margin: auto;
  max-width: 100%;
  min-width: 60%;
}

.ItemForm-preview {
  height: 400px;
  width: 400px;
  object-fit: contain;
}

.ItemForm-label {
  font-size: 1rem;
  text-align: center;
}

.ItemForm-input {
  font-size: 1rem;
  padding: .2rem;
  text-align: left;
  width: 80%;
}

.ItemForm-button Button{
  margin: .5rem;
}

.ItemForm-button {
  margin: .5rem;
}