.Admin-tab {
  text-transform: capitalize;
  background-color: #455169;
  color: white;
  font-size:1.5em;
}

.Admin-buttons {
  margin-bottom: 2rem;
}

.Admin-buttons Button {
  margin: 0 .5rem;
}

.Admin-content {
  margin-bottom: 5rem;
}

.Admin-title a.active {
  text-decoration: underline;
}

.Admin-link {
  text-decoration: none;
  color: white;
}

.Admin-link:hover {
  color: slategray;
}

.Admin-content Button {
  min-height: 0;
  min-width: 0;
  padding: .5rem;
}