.NavBar {
  padding: 1rem 2rem;
  color: white;
  justify-content: space-between;
}

.NavBar-left {
  display: flex;
}

.HomeNav {
  display: flex;
  text-decoration: none;
  color: lightsteelblue;
}

#HomeNav-title {
  margin: auto;
}

.AdminNav-menu {
  color: #2E3B55;
  text-decoration: none;
}

#NavBar-button {
  background-color: lightsteelblue;
  border-radius: 5px;
  border-color: lightslategray;
  height: 3.5vh;
}

#NavBar-input {
  border-radius: 5px;
  height: 3vh;
}

.NavBar-anon {
  justify-content: flex-end;
}

#NavBar-icon {
  height: 2.5rem;
}

.NavBar a.active:not(.AdminNav-menu) {
  color: white;
  text-decoration: none;
}

.NavBar-link {
  text-decoration: none;
  color: lightsteelblue;
}

.NavBar-link:hover {
  color: white;
  text-decoration: none;
}

.AdminNav-menu:hover {
  text-decoration: none;
}