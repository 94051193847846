.LoginForm {
  padding: .5rem;
  height: 40vh;
}

.LoginForm-label {
  font-size: 1rem;
  text-align: center;
}

.LoginForm-input {
  font-size: 1rem;
  padding: .25rem;
  text-align: left;
}

.LoginForm-button {
  margin: 1rem 0;
}