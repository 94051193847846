.Users-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.UserItem {
  border-bottom: 1px solid slategray;
}

.Users-title {
  color: white;
  background-color: slategray;
  font-size: calc(8px + 2vmin);
}

.Users-icon {
  width: 5vw;
  padding-right: .5rem;
}

.Users-user {
  width: 12vw;
  text-align: left;
  vertical-align: middle;
}

.Users-balance {
  width: 12vw;
  text-align: center;
}

.Users-active {
  width: 10vw;
}

.Users-created {
  width: 18vw;
}

.Users-view {
  width: 10vw;
}

.Users-update {
  width: 10vw;
}


.UserItem {
  font-size: calc(7px + 1.75vmin);
}

.UserItem-icon {
  text-align: center;
}

.UserItem-profileImg {
  vertical-align: middle;
  height: 50px;
  max-width: 50px;
  object-fit: contain;
}

.UserItem-username {
  text-align: left;
}

.Button-text {
  margin-left: .25rem;
}