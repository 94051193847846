.Transactions-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  text-align: left;
}

.Transactions-title {
  color: white;
  background-color: slategray;
  font-size: calc(8px + 2vmin);
}

.Transactions-id {
  width: 5vw;
  text-align: center;
}

.Transactions-date {
  width: 22vw;
}

.Transactions-type {
  width: 20vw;
}

.Transactions-total {
  width: 20vw;
}

.TransactionRow-type {
  text-transform: capitalize;
}

.TransactionRow {
  border-bottom: 1px solid slategray;
  font-size: calc(7px + 1.75vmin);
}

.TransactionRow td {
  padding: .5rem;
}

.TransactionRow-id {
  text-align: center;
}